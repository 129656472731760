
import TransactionService from "@/service/transaction-service/TransactionService";
import { useStore } from "@/store";
import { Status } from "@/types/master/Status.interface";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    transactionInvoice: {
      required: true,
      type: String,
    },
    statusId: {
      required: true,
      type: [String, Number],
    },
    statusName: {
      required: true,
      type: String,
    },
    isDisplayInvoice: {
      required: false,
      type: Boolean,
      default: true,
    },
    tittle: {
      required: true,
      type: String,
    },
    isHistory: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const transactionService = new TransactionService();
    const statusList = ref<Status[]>([]);
    const statusListDisplay = ref<Status[]>([]);
    const statusSelected = ref<Status>({
      id: 0,
      name: "",
    });

    const statusDefault = {
      width: "30px",
      height: "30px",
      "background-color": "#d3d3d3",
      "border-color": "#d3d3d3",
      "border-radius": "50%",
      padding: "2px 11px",
      color: "white",
    };

    const getStatusList = async () => {
      await transactionService.getStatus().then((response: Status[]) => {
        statusList.value = response;
        if (!props.isHistory) {
          statusListDisplay.value = statusList.value.splice(0, 6);
        } else {
          statusListDisplay.value = response;
        }
        getStarusSelected();
      });
    };

    const getStatusStyle = (id: number) => {
      let style = {
        width: "30px",
        height: "30px",
        "background-color": "#d3d3d3",
        "border-color": "#d3d3d3",
        "border-radius": "50%",
        padding: "2px 11px",
        color: "white",
      };
      if (id == props.statusId) {
        style = {
          width: "30px",
          height: "30px",
          "background-color": computed(() => store.getters.getBrand).value
            .colorThemeMain,
          "border-color": computed(() => store.getters.getBrand).value
            .colorThemeMain,
          "border-radius": "50%",
          padding: "2px 11px",
          color: "white",
        };
      } else {
        style = {
          width: "30px",
          height: "30px",
          "background-color": "#d3d3d3",
          "border-color": "#d3d3d3",
          "border-radius": "50%",
          padding: "2px 11px",
          color: "white",
        };
      }
      return style;
    };

    const getStarusSelected = () => {
      let status = statusListDisplay.value.find(
        (el: Status) => el.id == props.statusId
      ) as Status;
      statusSelected.value = status;
    };

    onMounted(async () => {
      await getStatusList();
    });
    return {
      statusList,
      statusSelected,
      statusDefault,
      statusListDisplay,
      getStatusStyle,
      brandConfig: computed(() => store.getters.getBrand),
    };
  },
});
