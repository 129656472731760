
import { useStore } from "@/store";
import { CartActionTypes } from "@/store/modules/cart/action-types";
import {
  Freeproducts,
  Product,
} from "@/types/transaction/TransactionPromotion";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
} from "vue";
import ModalFreeProduct from "./ModalFreeProduct.vue";

export default defineComponent({
  props: {
    freeProducts: {
      required: true,
      type: Array as PropType<Freeproducts[]>,
    },
    isEdit: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  components: {
    ModalFreeProduct,
  },
  emits: ["onSubmitFreeProduct"],
  setup(props, { emit }) {
    const store = useStore();
    const freeProductsDisplay = ref<Freeproducts[]>([]);
    const selectedPromotion = ref<Freeproducts>({
      limitProduct: 0,
      products: [],
      promotionId: 0,
      promotionIndex: 0,
      promotionName: "",
      poductSelectedCount: 0,
    });
    const modalFreeProduct = ref<InstanceType<typeof ModalFreeProduct>>();
    const forceRefresh = ref<boolean>(true);
    const modalKey = ref<number>(0);

    const refreshData = async () => {
      freeProductsDisplay.value = computed(() =>
        JSON.parse(JSON.stringify(props.freeProducts))
      ).value;

      await onCheckFreeProductDisableFlag();
    };

    const onCheckFreeProductDisableFlag = () => {
      let countFlag = 0;
      freeProductsDisplay.value.forEach((promotion: Freeproducts) => {
        let freeProductSelectedCount = 0;
        promotion.poductSelectedCount = 0;
        promotion.products.forEach((product: Product) => {
          if (product.selected) {
            freeProductSelectedCount =
              freeProductSelectedCount + product.quantity;
            promotion.poductSelectedCount = freeProductSelectedCount;
          }
        });

        if (freeProductSelectedCount < promotion.limitProduct) {
          countFlag = countFlag + 1;
        }
      });
      if (countFlag > 0) {
        store.dispatch(CartActionTypes.SET_IS_FREE_PRODUCT_DISABLED_FLAG, true);
      } else {
        store.dispatch(
          CartActionTypes.SET_IS_FREE_PRODUCT_DISABLED_FLAG,
          false
        );
      }
    };

    const onpenModalFreeProduct = async (promotion: Freeproducts) => {
      console.log(promotion.promotionId);
      selectedPromotion.value = promotion;
      nextTick(() => {
        modalFreeProduct.value?.openModal();
      });
    };

    const onSubmitFreeProduct = async (freeProduct: Freeproducts) => {
      let freeProductsDisplayIndex = freeProductsDisplay.value.findIndex(
        (el: Freeproducts) => el.promotionIndex == freeProduct.promotionIndex
      );

      if (freeProductsDisplayIndex > -1) {
        freeProductsDisplay.value[freeProductsDisplayIndex] = freeProduct;
        emit("onSubmitFreeProduct", freeProduct, freeProductsDisplayIndex);
        await onCheckFreeProductDisableFlag();
      }
    };

    const onAddQuantity = async (index: number, pindex: number) => {
      freeProductsDisplay.value[index].products[pindex].quantity =
        freeProductsDisplay.value[index].products[pindex].quantity + 1;

      emit("onSubmitFreeProduct", freeProductsDisplay.value[index], index);
      await onCheckFreeProductDisableFlag();
    };

    const onRemoveQuantity = async (index: number, pindex: number) => {
      freeProductsDisplay.value[index].products[pindex].quantity =
        freeProductsDisplay.value[index].products[pindex].quantity - 1;

      emit("onSubmitFreeProduct", freeProductsDisplay.value[index], index);
      await onCheckFreeProductDisableFlag();
    };

    onMounted(async () => {
      freeProductsDisplay.value = computed(() =>
        JSON.parse(JSON.stringify(props.freeProducts))
      ).value;

      await onCheckFreeProductDisableFlag();
    });

    return {
      isDisableForFreeProduct: computed(
        () => store.getters.getIsFreeProductDisableFlag
      ),
      freeProductsDisplay,
      modalFreeProduct,
      selectedPromotion,
      forceRefresh,
      modalKey,
      backgroundColor: store.getters.getBrand.colorThemeMain,
      onpenModalFreeProduct,
      onSubmitFreeProduct,
      onAddQuantity,
      onRemoveQuantity,
      refreshData,
    };
  },
});
