
import { useStore } from "@/store";
import {
  Freeproducts,
  Product,
} from "@/types/transaction/TransactionPromotion";
import { computed, defineComponent, PropType, ref, watch } from "vue";

export default defineComponent({
  props: {
    selectedPromotion: {
      required: true,
      type: Object as PropType<Freeproducts>,
    },
  },
  emits: ["onSubmitFreeProduct"],
  setup(props, { emit }) {
    const displayModal = ref(false);
    const selectedPromotionDisplay = ref<Freeproducts>();
    const validateMessage = ref<string>("");
    const isError = ref<boolean>(false);
    const store = useStore();

    const onChangeProductSelected = () => {
      if (onValidateFreeProduct() && isError.value) {
        validateMessage.value = `กรุณาเลือกของแถมจำนวน ${selectedPromotionDisplay.value?.limitProduct} ชื้น`;
      } else {
        validateMessage.value = "";
      }
    };

    const onSubmitFreeProduct = () => {
      if (onValidateFreeProduct()) {
        validateMessage.value = `กรุณาเลือกของแถมจำนวน ${selectedPromotionDisplay.value?.limitProduct} ชื้น`;
        isError.value = true;
        return;
      }

      emit("onSubmitFreeProduct", selectedPromotionDisplay.value);
      closeModal();
    };

    const onAddQuantity = (index: number) => {
      if (selectedPromotionDisplay.value) {
        selectedPromotionDisplay.value.products[index].quantity =
          selectedPromotionDisplay.value?.products[index].quantity + 1;

        if (
          onValidateFreeProduct() &&
          isError.value &&
          selectedPromotionDisplay.value?.products[index].selected
        ) {
          validateMessage.value = `กรุณาเลือกของแถมจำนวน ${selectedPromotionDisplay.value?.limitProduct} ชื้น`;
        } else {
          validateMessage.value = "";
        }
      }
    };

    const onRemoveQuantity = (index: number) => {
      if (selectedPromotionDisplay.value) {
        selectedPromotionDisplay.value.products[index].quantity =
          selectedPromotionDisplay.value?.products[index].quantity - 1;

        if (
          onValidateFreeProduct() &&
          isError.value &&
          selectedPromotionDisplay.value?.products[index].selected
        ) {
          validateMessage.value = `กรุณาเลือกของแถมจำนวน ${selectedPromotionDisplay.value?.limitProduct} ชื้น`;
        } else {
          validateMessage.value = "";
        }
      }
    };

    const onValidateFreeProduct = () => {
      let count = 0;
      selectedPromotionDisplay.value?.products.forEach((product: Product) => {
        if (product.selected) {
          count = count + product.quantity;
        }
      });
      if (selectedPromotionDisplay.value) {
        selectedPromotionDisplay.value.poductSelectedCount = count;
      }
      if (count !== selectedPromotionDisplay.value?.limitProduct) {
        return true;
      } else {
        return false;
      }
    };

    const openModal = async () => {
      selectedPromotionDisplay.value = computed(() =>
        JSON.parse(JSON.stringify(props.selectedPromotion))
      ).value;
      displayModal.value = true;
    };
    const closeModal = () => {
      displayModal.value = false;
    };

    return {
      displayModal,
      selectedPromotionDisplay,
      validateMessage,
      backgroundColor: store.getters.getBrand.colorThemeMain,
      openModal,
      closeModal,
      onSubmitFreeProduct,
      onChangeProductSelected,
      onAddQuantity,
      onRemoveQuantity,
    };
  },
});
